import {
  type ClientLoaderFunctionArgs,
  type ShouldRevalidateFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useMemo } from 'react';
import { $path } from 'remix-routes';

import {
  AdminPublicLibraryLayout,
  useAdminPublicLibraryData,
} from '../../app/components/PublicLibrary/AdminPublicLibraryLayout';
import { PublicLibraryBreadcrumbs } from '../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import { useGameLikeWorkspace } from '../components/Game/GameCenter';
import {
  PageManagerUtils,
  PageSectionUtils,
} from '../components/PageManager/utils';
import { TagUtils } from '../components/Tagging/utils';
import { GamePackTagDetails } from '../pages/GamePack';
import { apiService } from '../services/api-service';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { slug } = action.params;
  if (!slug) throw new Error('Tag slug is required');

  const resp = await apiService.tag.getTagBySlug(slug);
  const tag = resp.data.tag;

  return {
    tag,
  };
}

export function shouldRevalidate(args: ShouldRevalidateFunctionArgs) {
  const { slug: currentSlug } = args.currentParams;
  const { slug: nextSlug } = args.nextParams;
  if (currentSlug === nextSlug) {
    return false;
  }
  return args.defaultShouldRevalidate;
}

export function Component() {
  const { page, pageName } = useAdminPublicLibraryData();
  const { tag } = useLoaderData<typeof clientLoader>();

  const tags = useMemo(() => PageManagerUtils.GetGamePackTags(page), [page]);

  const navigate = useNavigate();
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  const routePrefix = $path('/admin/public-library/:name', {
    name: pageName,
  });

  return (
    <AdminPublicLibraryLayout
      isSelectedSection={(section) => PageSectionUtils.HasTag(section, tag.id)}
    >
      <div className='w-full pb-10'>
        <GamePackTagDetails
          tags={tags}
          tag={tag}
          personalized={false}
          showReorderButton={!TagUtils.IsVirtualTag(tag.slug)}
          onViewTag={(tag) => navigate(`${routePrefix}/tags/${tag.slug}`)}
          onGamePackClick={(pack) => setActiveGamePack(pack)}
          breadcrumb={
            <PublicLibraryBreadcrumbs
              items={[
                {
                  label: 'Explore',
                  kind: 'link',
                  to: $path('/admin/public-library/:name', {
                    name: pageName,
                  }),
                },
                {
                  label: tag.name,
                  kind: 'noop',
                },
              ]}
            />
          }
        />
      </div>
    </AdminPublicLibraryLayout>
  );
}
